<template>
  <div>
    <table class="table is-fullwidth">
      <tbody>
        <tr>
          <td>
            Allow location booking terms
          </td>
          <td width="100">
            <div class="tabs is-toggle is-fullwidth">
              <ul>
                <li
                  :class="{
                    'is-active': !locationChannelState.LocationBookingTermAllowed,
                  }"
                >
                  <a @click="updateSettings">
                    <span>No</span>
                  </a>
                </li>
              </ul>
              <li
                :class="{
                  'is-active': locationChannelState.LocationBookingTermAllowed,
                }"
              >
                <a @click="updateSettings">
                  <span>Yes</span>
                </a>
              </li>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import channelProvider from '@/providers/channel'

export default {
  components: {},

  props: {},

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),

    locationChannelState() {
      if (this.locationState) {
        let channel = this.locationState.Channels.find(
          (c) => c.ChannelId === this.channelId
        )
        if (channel) {
          return channel
        }
      }
      return null
    },
  },

  created() {},

  methods: {
    ...mapMutations('locationStore', ['setLocationState']),

    updateSettings() {
      channelProvider.methods
        .updateLocationChannelBookingTerm(this.channelId, this.locationId)
        .then((response) => {
          if (response.status == 200) {
            let channelIndex = this.locationState.Channels.findIndex(
              (c) => c.ChannelId === this.channelId
            )
            if (channelIndex > -1) {
              Vue.set(this.locationState.Channels, channelIndex, response.data)
              this.setLocationState(this.locationState)
            }
          }
        })
    },
  },
}
</script>

<style></style>
